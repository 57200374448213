import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pannellum } from "pannellum-react";
// import myImage from "./images/milan.jpg";
import general_fetch from "./utils/fetch";
import Carousel from "./components/carousel";
import Hotspots from "./components/hotspots";
import {Tooltip} from 'antd'

// import "./styles.css";
// const crypto = require('crypto');
const format = require('biguint-format');
const panorama_id = window.location.href.split('/')[3]

function App() {
  const [image, set_image] = useState({})
  const [temp_image, set_temp_image] = useState({})
  const [fetched_pano_images, set_fetched_pano_images] = useState([]);
  const [final_path_array, set_final_path_array] = useState([]);
  const [hotspot_data, set_hotspot_data] = useState([]);
  const [temp_hotspot_data, set_temp_hotspot_data] = useState([]);
  const [mousePos, setMousePos] = useState({});
  const [carousel_y, set_carousel_y] = useState(0);

  const update_image = (pano_image) => {
    console.log('onclick update', pano_image, hotspot_data)
      set_image(pano_image);
  }

  const calculate_pitch_yaw = (next_point) => {
    let temp_coords = {x : -(image.camera_pos.x - next_point.x), y: -(image.camera_pos.y - next_point.y), z: -(image.camera_pos.z - next_point.z)}
    let yaw =  (Math.atan2(temp_coords.z, temp_coords.x)+Math.PI)*180/Math.PI + 90
    let pitch = (Math.atan2(temp_coords.y, Math.sqrt(Math.pow(temp_coords.x,2) + Math.pow(temp_coords.z,2)))+Math.PI)*180/Math.PI
    console.log('temp_coords',temp_coords, yaw, pitch)
    return [yaw, pitch]
  }

  const fetch_pano_images = async() => {
    if(panorama_id){
      try{
        let resp = await window.general_fetch({url:'panorama_tour/get', body:{id:panorama_id}})
        resp = await window.general_fetch({url:'render/get_by_id', rtx_path:true, body:{id: resp.renders.map(x => x.render_id)}})
        let temp_images = resp && resp.length ? resp.map((x,idx) => ({pkey:idx, 
                                                                      imageSrc: global.config.static_assets_path + x.output_file_path, 
                                                                      camera_pos:{x:x.params.camera_position_x, y:x.params.camera_position_y, z:x.params.camera_position_z}, 
                                                                      yaw: (Math.atan2(x.params.camera_position_z, x.params.camera_position_x)+Math.PI)*180/Math.PI,
                                                                      pitch: (Math.atan2(x.params.camera_position_y, Math.sqrt(Math.pow(x.params.camera_position_x,2) + Math.pow(x.params.camera_position_z,2)))+Math.PI)*180/Math.PI})) : []
        console.log( "panorama_id 1", temp_images)
        set_fetched_pano_images(temp_images)
        if(temp_images && temp_images.length) set_image(temp_images[0])
        console.log( "panorama_id 2", temp_images)
      }catch(err){
        console.log(err)
      }
    }
  }

  const play_fn = () => {
    let t_image = JSON.parse(JSON.stringify(image))
    set_image({})
    set_temp_image(t_image)
  }

  function distance(p, given_point) {
    return Math.sqrt(Math.pow(given_point.x - p.x, 2) + Math.pow(given_point.y - p.y, 2) + Math.pow(given_point.z - p.z, 2))
  }

  useEffect(() => {
    fetch_pano_images()
  }, []);

  useEffect(() => {
      if(fetched_pano_images && fetched_pano_images.length>1 && !fetched_pano_images[0].linkedImages){
		let temp_fetched_pano_images = JSON.parse(JSON.stringify(fetched_pano_images))
        let min_x = Number.MAX_SAFE_INTEGER, min_y = Number.MAX_SAFE_INTEGER, min_z = Number.MAX_SAFE_INTEGER
        let all_points = fetched_pano_images.map(single_image => {console.log('min_x', single_image)
                                                                  min_x = Math.min(min_x, single_image.camera_pos.x);
                                                                  min_y = Math.min(min_y, single_image.camera_pos.y); 
                                                                  min_z = Math.min(min_z, single_image.camera_pos.z);
                                                                  return single_image.camera_pos})

        let starting_point = all_points.reduce((a, b) => distance(a, {x:min_x, y:min_y, z:min_z}) < distance(b, {x:min_x, y:min_y, z:min_z}) ? a : b);
        
        let starting_image_index = temp_fetched_pano_images.findIndex(o => JSON.stringify(o.camera_pos) === JSON.stringify(starting_point))
		let starting_image_pkey = temp_fetched_pano_images[starting_image_index].pkey
		console.log('starting_image_index11', temp_fetched_pano_images[starting_image_index])
		temp_fetched_pano_images.splice(starting_image_index, 1)
        // console.log('starting_point', starting_point, fetched_pano_images.find(o => JSON.stringify(o.camera_pos) === JSON.stringify(starting_point)), starting_image_index)
        let path_array = [{pkey:starting_image_pkey, coords:starting_point}]
        all_points.splice(starting_image_pkey, 1)
       
        while(all_points.length){
          // all_points.map(point => console.log('printing all points', point))
          // console.log('printing all points ended')
          // path_array.map(point => console.log('printing path array', point.coords))
          // console.log('printing path array ended')
          starting_point = all_points.reduce((a, b) => distance(a, starting_point) < distance(b, {x:min_x, y:min_y, z:min_z}) ? a : b);
          let all_ponts_id = all_points.findIndex(o => JSON.stringify(starting_point) === JSON.stringify(o))
          // console.log('all_ponts_id', all_ponts_id, starting_point)
          all_points.splice(all_ponts_id, 1)
          let fetched_pano_images_id = temp_fetched_pano_images.findIndex(o => JSON.stringify(o.camera_pos) === JSON.stringify(starting_point))
		  let fetched_pano_images_pkey = temp_fetched_pano_images[fetched_pano_images_id].pkey
		//   console.log('starting_image_index', temp_fetched_pano_images[fetched_pano_images_id])
		  temp_fetched_pano_images.splice(fetched_pano_images_id, 1)
          path_array.push({pkey:fetched_pano_images_pkey, coords:starting_point})
        //   console.log('left_over_points', all_points, path_array, starting_point)
          // console.log(fetched_pano_images)
        }
        set_final_path_array(path_array)
      }
  }, [fetched_pano_images]);

  useEffect(() => {
    if(fetched_pano_images && fetched_pano_images.length && final_path_array && final_path_array.length && image && image.imageSrc) {
      // console.log('onclick update 2', image)
        let final_path_index = final_path_array.findIndex(o => o.pkey === image.pkey)
        // console.log('starting_image_index2222', final_path_index, final_path_array, image)
        if(final_path_index ==0){
          
            let next_point = final_path_array[1].coords
            let data = calculate_pitch_yaw(next_point)
            set_hotspot_data([])
            set_temp_hotspot_data([{yaw: data[0], pitch: data[1], next_image: fetched_pano_images[final_path_array[1].pkey], random_id:String(Math.random()*Math.pow(10,5))}])
            // console.log('final_path_index 0', image, [{yaw: data[0], pitch: data[1], next_image: fetched_pano_images[final_path_array[1].pkey]}])
        }else if(final_path_index == final_path_array.length-1){
            let next_point = final_path_array[final_path_array.length-2].coords
            let data = calculate_pitch_yaw(next_point)
            set_hotspot_data([])
            set_temp_hotspot_data([{yaw: data[0], pitch: data[1], next_image: fetched_pano_images[final_path_array[final_path_array.length-2].pkey], random_id:String(Math.random()*Math.pow(10,5))}])
            // console.log('final_path_index 1', image, [{yaw: data[0], pitch: data[1], next_image: fetched_pano_images[final_path_array[final_path_array.length-2].pkey]}])
        }else{
            let next_point = final_path_array[final_path_index-1].coords
            let data1 = calculate_pitch_yaw(next_point)
            next_point = final_path_array[final_path_index+1].coords
            let data2 = calculate_pitch_yaw(next_point)
            set_hotspot_data([])
            set_temp_hotspot_data([{yaw: data1[0], pitch: data1[1], next_image: fetched_pano_images[final_path_array[final_path_index-1].pkey], random_id:String(Math.random()*Math.pow(10,5))}, {yaw: data2[0], pitch: data2[1], next_image: fetched_pano_images[final_path_array[final_path_index+1].pkey], random_id:String(Math.random()*Math.pow(10,5))}])
            // console.log('final_path_index 2', image, [{yaw: data1[0], pitch: data1[1], next_image: fetched_pano_images[final_path_array[final_path_index-1].pkey], random_id:String(Math.random()*Math.pow(10,5))}, {yaw: data2[0], pitch: data2[1], next_image: fetched_pano_images[final_path_array[final_path_index+1].pkey], random_id:String(Math.random()*Math.pow(10,5))}])
        }
    }
  }, [image, fetched_pano_images, final_path_array]);

  useEffect(() => {
    console.log('onclick hotspot_data updated', hotspot_data);
  }, [hotspot_data]);

  useEffect(() => {
    // console.log('onclick temp_hotspot_data updated', temp_hotspot_data);
      set_hotspot_data(temp_hotspot_data)
  }, [temp_hotspot_data]);

  useEffect(() => {
      if(temp_image.imageSrc){
        set_image(temp_image)
      }
  }, [temp_image]);

  // useEffect(() => {
  //     console.log('mousePos', mousePos, carousel_y)
  // }, [mousePos]);

  useEffect(() => {
    if(document.getElementById('carousel_div')){
      set_carousel_y(document.getElementById('carousel_div').getBoundingClientRect().y)
    }
  }, []);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  return (
    <div className="App">
      <div>
      <Pannellum
        width="100%"
        height="100vh"
        image={image.imageSrc}
        // pitch={image.pitch}
        // yaw={image.yaw}
        // hfov={100}
        roll = {200}
        autoLoad
        autoRotate={-5}
        autoRotateSpeed = {5}
        autoRotateInactivityDelay = {700}
        // autoRotateStopDelay = {7000}
        showZoomCtrl={false}
        onLoad={() => {
          console.log("panorama loaded");
        }}
      >
        {/* <Pannellum.Hotspot
            type="custom"
            pitch={100}
            yaw={100}
            // handleClick={() => update_image(single_hotspot.next_image)}
            name="hs1"
          />
          <Pannellum.Hotspot
          type="custom"
          pitch={0}
          yaw={50}
          // handleClick={() => update_image(single_hotspot.next_image)}
          name="hs21"
          /> */}
      
      {/* <Hotspots active_image = {image} pano_images = {fetch_pano_images} final_path_array = {final_path_array} update_image = {update_image}/> */}
      {/* {console.log("re-rendering... hotspot changed")} */}
      {
        /*hotspot_data && hotspot_data.length ?*/ hotspot_data.map((single_hotspot, idx) => (
            <Pannellum.Hotspot
                type="custom"
                pitch={single_hotspot.pitch}
                yaw={single_hotspot.yaw}
                handleClick={() => {update_image(single_hotspot.next_image)}}
                name="hs1"
                key = {single_hotspot.random_id}
                id = {single_hotspot.random_id}
            />
        ))
      }
        
      </Pannellum>
    </div>
    <Tooltip title={'Restart Animation'} placement="right" >
        <div className="flex-center" style={{position:'absolute', left:'40px', top:'4px', backgroundColor:'white', height:'26px', width:'26px', cursor:'pointer', borderRadius:'4px', border:'1px solid #999'}} onClick={play_fn}>
          <i className="fa fa-redo-alt" style={{color:'black', fontSize:'16px'}}/>
        </div>
    </Tooltip>
    {
      mousePos.y > carousel_y ?
      <div className="flex-center" id='carousel_parent' style={{position:'absolute', left:0, bottom:0, zIndex:'10000', width:'100vw', backgroundColor:'rgba(197,199,207,0.2)'}}>
        <Carousel pano_shots = {fetched_pano_images} update_image = {update_image}/>
      </div>:
      (
        mousePos.y ? 
          <div className="flex-center" style={{position:'absolute', left:0, bottom:0, width:'100vw', alignSelf:'center'}}>
              <div style={{width:'0px', height:"0px", borderRightColor:'#A7A8B3', borderTopColor:'transparent', borderStyle:'solid', borderWidth:'8px 10px 0px 0px'}}></div>
              <div style={{width:'45vw', height:'8px', backgroundColor:'#A7A8B3'}}></div>
              <div style={{width:'0px', height:"0px", borderLeftColor:'#A7A8B3', borderTopColor:'transparent', borderStyle:'solid', borderWidth:'8px 0px 0px 10px'}}></div>
          </div> 
          : 
          <div className="flex-center" id='carousel_parent' style={{position:'absolute', left:0, bottom:0, zIndex:'10000', width:'100vw', backgroundColor:'rgba(197,199,207,0.2)'}}>
            <Carousel pano_shots = {fetched_pano_images} update_image = {update_image}/>
          </div>
      )
    }
    </div>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);

export default App;