import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pannellum } from "pannellum-react";
// import myImage from "./images/milan.jpg";
import general_fetch from "../utils/fetch";

const Carousel = ({pano_shots, update_image}) => {

    useEffect(() => {
        console.log('pano_shots carousel', pano_shots)
    }, [pano_shots]);

    return(
        <>
            <i className="fa fa-caret-left" style={{ color:'black', fontSize:'24px', marginRight:'8px', cursor:'pointer'}} onClick={() => {let y = document.getElementById('carousel_div');console.log('y.scroll left', y.scrollLeft); y.scrollLeft = y.scrollLeft-100}}/>
            <div id='carousel_div' className="flex-property carousel" style={{height:'120px', maxWidth:'60vw', overflowX:'auto', transform:'scale(0.9)'}}>
            {
                pano_shots && pano_shots.length ? pano_shots.map((single_image, idx) => (
                    <img key = {idx} src={single_image.imageSrc} width='200px' height='112px' style={{cursor:'pointer', marginRight:'8px'}} onClick={() => update_image(single_image)}/>
                )):''
            }
            </div>
            <i className="fa fa-caret-right" style={{ color:'black', fontSize:'24px', cursor:'pointer'}} onClick={() => {let y = document.getElementById('carousel_div');console.log('y.scroll right', y.scrollLeft); y.scrollLeft = y.scrollLeft+100}}/>
        </>
    )
}

export default Carousel