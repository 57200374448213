var config_settings = {
	"production":{
		server_path: 'https://productionbackend.infurnia.com/',
		rtx_path: 'https://productionbackend.infurnia.com/',
		static_file_server_path: 'https://productionbackend.infurnia.com/',
		static_assets_path: 'https://staticassets.infurnia.com/'
	},
	"preprod":{
		server_path: 'https://preprodbackend.infurnia.com/',
		rtx_path: 'https://preprodbackend.infurnia.com/',
		static_assets_path: 'https://preprodstaticassets.infurnia.com/'
	},
	"staging":{
		server_path: 'https://stagebackend.infurnia.com/',
		rtx_path: 'https://stagebackend.infurnia.com/',
		static_assets_path: 'https://stagestaticassets.infurnia.com/'
		// static_file_server_path: 'https://stagebackend.infurnia.com/',
		// auth_redirect_path: 'https://stagedesign.infurnia.com/login/admin',
		// website_url: 'https://stagedesign.infurnia.com/',
	},
	"dev":{
		server_path: 'https://devbackendrunning.infurnia.com/',
	}
}

let test_fn = () => {
    return 0;
}

const check_401 = (allow_visible) => {
	test_fn(allow_visible)
}


var config = config_settings[process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'staging'];

global.config = config
global.config.check_401 = check_401

window.segment_id=global.config.segment_id;